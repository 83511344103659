<style scoped>
.head-box{flex:1 1 auto; display: flex; align-items: flex-end;}
.el-dropdown{font-size:15px !important; color:#999 !important; cursor:pointer;}
.el-dropdown-link{color:#76c0e0; padding:2px; font-size:16px; cursor: pointer;}
.el-dropdown-menu, .el-popper__arrow, .el-popper__arrow::before{background-color:#20323a !important;}
.el-dropdown-menu__item{color:#bbb !important;}
.el-dropdown-menu__item:hover{color:#eee !important;}
</style>
<template>
	<div class="head-box">
        <span style="color:#86b0c2; padding-right:20px; cursor:pointer" v-html="gd.app_name" @click="click_root"></span>
        <span style="font-size:14px; color:#ddd;  margin-bottom: -1px">欢迎您：</span>
        <span style="font-size:16px; color:#fff" v-html="gd.me.truename"></span>
        <span style="width:10px;">&nbsp;</span>
        <span style="font-size:14px; color:#caefff; margin-bottom: -2px">当前操作小区：</span>
        <el-dropdown trigger="click" :max-height="500" @command="click_estate">
            <span class="el-dropdown-link">
                <span v-html="gd.estate.length > 0 ? gd.estate[estate_idx].name : '请选择'"></span>
                <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item v-for="(item,idx) in gd.estate" :key="idx" :command="idx">{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
        <span style="flex:1 1 auto;">&nbsp;</span>
        <el-button type="info" @click="logout">退出登录</el-button>
    </div>
</template>

<script>
import {inject,reactive} from 'vue'
export default {
    name: 'CommonTop',
    setup:function(){
        const gd = reactive(inject('gd'));
        return {gd};
    },//setup
    data:function(){
        return {
            estate_idx:0
        };
    },//data
    watch:{
        'gd.estate':function(val){
            //console.log('gd.estate change')
            for(let i=0; i<val.length; i++){
                if(val[i].id == this.gd.me.eid){ this.estate_idx = i; break;}
            }
        }
    },
    mounted:function(){
        //console.log(this.gd.estate);
    },//mounted
    methods:{
        click_root:function(){
            this.$router.push("/main");
        },//click_root
        click_estate:function(idx){
            this.estate_idx = idx;
            this.$emit('change_estate', this.gd.estate[idx].id);
            this.axios.post("/api/user/change_estate",{id:this.gd.estate[idx].id}).then(ret=>{
                if(ret.data){
                    this.gd.me.role = ret.data.role;
                    localStorage.setItem('me', JSON.stringify(this.gd.me));
                }
            });
        },//click_estate
        logout:function(){
            localStorage.removeItem('me');
            sessionStorage.removeItem('me');
            this.$emit("click_logout");
        },//
    }
}
</script>