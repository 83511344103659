<style>
.el-menu{ text-align: left !important; min-width:99% !important; border-right: 0 !important;}
.el-menu a{text-decoration:none;}
.el-submenu__title{text-align: left !important; display:flex !important; align-items: center; padding:0 0 0 5px !important;}
.el-submenu__icon-arrow{position:static !important; margin:0 0 0 5px !important; color:#adcab8 !important;}
.el-menu-item{padding:0 0 0 20px !important; min-width:99% !important;}
.el-submenu .is-active{border-right: solid 5px #409EFF;}
</style>
<template>
	<el-menu class="el-menu" background-color="#20323a" text-color="#aaa" active-text-color="#caefff">
        <el-submenu v-for="(item,idx) in routes" :key="idx" :index="idx+''">
            <template #title>
                <i :class="item.meta.icon"></i>
                <span v-html="item.meta.title"></span>
            </template>
            <router-link v-for="sub in item.children" :key="sub.path" :to="sub.path">
                <el-menu-item :index="sub.path" v-if="can_i_see(sub)">
                    <i :class="sub.meta.icon"></i>
                    <span v-html="sub.meta.title"></span>
                </el-menu-item>
            </router-link>
        </el-submenu>
    </el-menu>
</template>

<script>
import {inject} from 'vue'
export default {
    name: 'CommonMenu',
    setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    computed:{
	    routes:function(){
	        return this.$router.getRoutes().filter(item=>{
	            return item.meta && item.meta.isbox;
	        });
	    },
    },
    methods:{
        can_i_see:function(item){
            //console.log(item);
            if(!item.meta.role){ return true; }
            if(item.meta.role.includes(this.gd.me.role)){ return true;}
            return false;
        },
    }
}
</script>