<style>
.el-container { height: 100%; }
.el-header { background: #20323a; font-size: 24px; color: #888; height: 60px; display: flex; align-items: center; }
.body { border-top: solid 1px #ccc; height: 100%; }
.show { color: #fff; }
.el-aside { background: #20323a; }
.el-main{ background: #e6e6e6; padding:2px !important; display: flex !important; flex-direction: column; align-items: stretch}
</style>
<template>
    <el-container>
        <el-header>
            <CommonTop @click_logout="click_logout" @change_estate="change_estate"></CommonTop>
        </el-header>
        <el-container class="body">
            <el-aside width="140px">
                <CommonMenu></CommonMenu>
            </el-aside>
            <el-main>
                <CommonMain @on_event="on_event"/>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import {inject} from 'vue'
import CommonTop from "../components/CommonTop.vue"
import CommonMenu from "../components/CommonMenu.vue"
import CommonMain from "../components/CommonMain.vue"
export default {
	components:{
		CommonTop,
		CommonMenu,
		CommonMain
	},
	setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    created:function(){ 
        this.load_my_estate();
    },//created
    methods:{
        change_estate:function(id){//顶上选了小区
            this.gd.me.eid = id;
            this.save_me_to_storage();
        },//change_estate
        save_me_to_storage:function(){
            let str_me = localStorage.getItem('me');
            if(str_me){
                localStorage.setItem('me', JSON.stringify(this.gd.me));
            }else{
                sessionStorage.setItem('me', JSON.stringify(this.gd.me));
            }
        },//save_me_to_storage
        load_my_estate:function(){
            this.axios.post("/api/estate/mylist").then(ret=>{
                if(!ret.ok){ this.$message.error(ret.msg); return;}
                this.gd.estate = ret.data;
            });
        },//load_my_estate
    	click_logout:function(){
    		this.$emit('on_event','click_logout');
    		//setTimeout(this[e],10);
    	},
        //组件发送的事件
        estate_change:function(){//Estate.vue 发送的小区有修改变动
            this.load_my_estate();
        },//estate_change  
        on_event:function(e){//其它携带方法名的事件
            setTimeout(this[e],10);
            //console.log(e);
        }
    }
}

</script>