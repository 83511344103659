import { createRouter, createWebHistory } from 'vue-router'
//import Meeting from '../views/Meeting.vue'
const routes = [{
        path: '/',
        name: 'Root',
        component: () => import('../components/CommonMain.vue'),
    },{
        path: '/main',
        name: 'Main',
        component: () => import('../views/Main.vue'),
    },{
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
    },{
        meta:{ title:'系统管理', icon: 'el-icon-setting', isbox:true },
        path: '/',
        component: () => import('../components/CommonMain.vue'),
        children:[{
            path: '/param',
            name: 'Param',
            component: () => import('../views/Param.vue'),
            meta:{ title: '参数配置', icon: 'el-icon-open' }
        },{
            path: '/areas',
            name: 'Areas',
            component: () => import('../views/Area.vue'),
            meta:{ title:"行政区划", icon: 'el-icon-map-location' }
        },{
            path: '/estate',
            component: () => import('../views/Estate.vue'),
            meta:{ title: '小区接入', icon: 'el-icon-guide', role:[5] }
        },{
            path: '/admin',
            component: () => import('../views/Admin.vue'),
            meta:{ title: '后台账号', icon: 'el-icon-user' }
        }
        // ,{
        //     path: '/user',
        //     component: () => import('../views/User.vue'),
        //     meta:{ title: '居民账号', icon: 'el-icon-user-solid' }
        // }
        ]
    },{
        meta:{ title: '业务菜单', icon: 'el-icon-folder-opened', isbox:true },
        path: '/',
        component: () => import('../components/CommonMain.vue'),
        children:[{
            path: '/role1',
            component: () => import('../views/Role1.vue'),
            meta:{ title: '物业账号', icon: 'el-icon-s-custom' }
        },{
            path: '/house',
            component: () => import('../views/House.vue'),
            meta:{ title: '房屋配置', icon: 'el-icon-office-building' }
        },{
            path: '/resident',
            component: () => import('../views/Resident.vue'),
            meta:{ title: '业主管理', icon: 'el-icon-guide' }
        },{
            path: '/question',
            component: () => import('../views/Question.vue'),
            meta:{ title: '调查问卷', icon: 'el-icon-document-checked' }
        },{
            path: '/vote',
            component: () => import('../views/Vote.vue'),
            meta:{ title: '投票选举', icon: 'el-icon-trophy' }
        }]
    },{
        meta:{ title: '资讯通知', icon: 'el-icon-document', isbox:true },
        path: '/',
        component: () => import('../components/CommonMain.vue'),
        children:[{
            path: '/channels',
            component: () => import('../views/Channel.vue'),
            meta:{ title: '资讯分类', icon: 'el-icon-folder-opened' }
        },{
            path: '/articles',
            component: () => import('../views/Article.vue'),
            meta:{ title: '管理资讯', icon: 'el-icon-edit' }
        }]
    },{
        meta:{ title: '短信中心', icon: 'el-icon-message', isbox:true },
        path: '/',
        component: () => import('../components/CommonMain.vue'),
        children:[{
            path: '/sms',
            component: () => import('../views/Sms.vue'),
            meta:{ title: '群发短信', icon: 'el-icon-mobile-phone' }
        },{
            path: '/plan',
            component: () => import('../views/Plan.vue'),
            meta:{ title: '历史任务', icon: 'el-icon-receiving' }
        }
        ,{
            path: '/verifycode',
            component: () => import('../views/VerifyCode.vue'),
            meta:{ title: '验证码日志', icon: 'el-icon-coin' }
        }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export {router,routes}