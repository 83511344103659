import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import "element-plus/lib/theme-chalk/index.css"
import "element-plus/lib/theme-chalk/display.css"
import {router} from './router'
import zhCn from "element-plus/lib/locale/lang/zh-cn"

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    //console.log(response)
    return response.data;
  }, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  });


const app = createApp(App).use(router)
app.use(VueAxios,axios)

app.use(ElementPlus, {locale: zhCn});
app.mount('#app')