<template>
	<el-config-provider :locale="locale">
	    <!-- <router-view :gd="gd" /> -->
	    <Layout v-if="gd.me && gd.me.token" @on_event="on_event"></Layout>
	    <Login v-else @logined="loginok"></Login>
    </el-config-provider>
</template>
<script>
import {provide,reactive} from "vue"
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import "./assets/css/common.css"
import Login from "./views/Login.vue"
import Layout from "./views/Layout.vue"

export default {
    components:{Login,Layout,ElConfigProvider},
    setup:function(){
        const gd = reactive({
            app_name:'安岳智慧物管',
            copy_right:'资阳移动&安岳房管局 @ 2021',
            base_url:`${window.location.protocol}//${window.location.host}`,
            //base_url:'http://localhost:5000',
            //base_url:'https://ay.zhwy.ren',
            me:null,
            estate:[]
        });
        provide('gd',gd);
        return { gd, locale: zhCn };
    },//setup
    created:function(){
        this.init();
    },
    watch:{
        'gd.me':function(val){
            if(val && val.token){
                this.axios.defaults.headers.common['Token'] = val.token;               
            }else{
                this.axios.defaults.headers.common['Token'] = '';
            }
        },//gd.me
    },
    methods:{
        init:function(){
            this.load_me_from_storage();
            this.axios.defaults.baseURL = this.gd.base_url;
            this.axios.defaults.headers.post['Content-Type'] = 'application/json';
            // 添加请求拦截器
            this.axios.interceptors.request.use(config=>{
                if(this.gd.me){
                    if(config.data == undefined){
                        config.data = {eid:this.gd.me.eid}
                    }else if(config.data.eid == undefined){
                        config.data.eid = this.gd.me.eid;
                    }
                }
                return config;
            }, error=>{
                // 对请求错误做些什么
                return Promise.reject(error);
            });
            
        },
        load_me_from_storage:function(){
            let str_me = localStorage.getItem('me');

            if(str_me){
                this.gd.me = JSON.parse(str_me);
            }else{
                str_me = sessionStorage.getItem('me');
                if(str_me){
                    this.gd.me = JSON.parse(str_me);
                }
            }
        },//load_me_from_storage
        loginok:function(me){//Login.vue 发送的登录成功
            this.gd.me = me;
        },//loginok
        click_logout:function(){//LayoutTop.vue 发送的退出登录
            this.gd.me = null;
            window.location = '/';
        },//click_logout
        on_event:function(e){//其它携带方法名的事件
            setTimeout(this[e],10);
        }
    }
}
</script>