<style scoped>
.page{background:url('../assets/img/login_bg.jpg') center center no-repeat; padding:0; margin:0; width:100%; height:100%; min-width:500px; min-height: 700px; position: relative; }
.right-pad{position:absolute; z-index:2; top:0; right:0; bottom:0; width:500px; background:rgba(0,0,0,0.5); display:flex; justify-content: center; align-items: center;}
.box{padding:30px; border-radius:5px; background:url('../assets/img/login-box.jpg') center bottom no-repeat;  }
.el-row+.el-row{margin-top: 20px;}
.title{font-size: 24px; color:#409EFF; text-align: center; font-weight: 600 }
input[type=text]{font-weight: bold; font-size:18px; }
::-webkit-input-placeholder {font-size:14px; font-weight: normal;}
.el-button{width:100%;}
.copy-right{font-size:14px; color:#666; text-align: center; font-weight: 300}
</style>

<template>
    <div class="page">
    	<div class="right-pad">
    		<div class="box">
	            <el-row>
	                <el-col :span="24"><div class="title" v-html="gd.app_name || ''"></div></el-col>
	            </el-row>
	            <el-row :gutter="18">
	                <el-col :span="24" >
	                    <el-input placeholder="请输入登录账号" prefix-icon="el-icon-user" v-model="username"></el-input>
	                </el-col>
	            </el-row>
	            <el-row :gutter="18">
	                <el-col :span="24">
	                    <el-input show-password placeholder="请输入登录密码" prefix-icon="el-icon-lock" v-model="passwd" @keypress="key_press"></el-input>
	                </el-col>
	                <!-- <el-col :span="10">
	                <el-button type="success" plain  @click="click_code" v-html="get_code_btn_text" :disabled="deny_get_code">获取验证码</el-button>
	                </el-col> -->
	            </el-row>
	            <el-row :gutter="18">
	                <el-col :span="24" style="text-align: left">
	                    <el-checkbox v-model="remember" border>记住我，以后自动登录</el-checkbox>
	                </el-col>
	            </el-row>
	            <el-row :gutter="18">
	                <el-col :span="24" >
	                    <el-button type="primary" style="width:100%;" @click="click_done">登录</el-button>
	                </el-col>
	            </el-row>
	            <el-row :gutter="18">
	                <el-col :span="24" >
	                    <div class="copy-right">{{gd.copy_right}}</div>
	                </el-col>
	            </el-row>
	        </div>
    	</div>
        
    </div>
</template>

<script>
import {inject} from 'vue'
export default {
    setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    data:function(){
        return {
            username:"",
            passwd:"",
            remember:false,
        };
    },
    mounted:function(){
        this.init();
    },//mounted
    methods:{
        init:function(){
            if(this.gd.me && this.gd.me.token && this.gd.me.token.length == 33){ this.token_login();}
        },//init
        token_login:function(){
            this.axios.post('/api/user/admin_token_login').then(ret=>{
                if(!ret.ok){
                    localStorage.removeItem('me');
                }else{
                    ret.data.token = this.gd.me.token;
                    localStorage.setItem('me', JSON.stringify(ret.data));
                    this.$emit('logined', ret.data);
                }
            });
        },//token_login
        key_press:function(e){

            if(e.keyCode == 13){
                this.click_done();
            }
        },//key_press
        click_done: function(){
            if(this.username.length < 3 || this.username.length > 20){ this.$message.error("请输入登录账号"); return;}
            if(this.passwd.length < 6){ this.$message.error("请输入登录密码"); return false;}

            this.axios.post("/api/user/admin_pass_login", {username: this.username, passwd:this.passwd}).then(ret=>{                
                if(ret.ok){
                    if(this.remember == true){
                        localStorage.setItem('me', JSON.stringify(ret.data));
                        sessionStorage.removeItem('me');
                    }else{
                        localStorage.removeItem('me');
                        sessionStorage.setItem('me', JSON.stringify(ret.data));
                    }
                    this.$emit('logined', ret.data);
                }else{
                    this.$message.error(ret.msg);
                    return false;
                }
            }).catch(err=>{ console.log(err); });
        }//login end
    }
}
</script>