<template>
  <router-view></router-view>
</template>

<script>
export default {
	name: 'CommonMain',
	created:function(){
		//console.log(window.location.pathname);
	    if(window.location.pathname == "/" || window.location.pathname == "/index.html"){
	    	this.$router.push('/main');
	    }
    },//created
}
</script>